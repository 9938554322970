import { Link } from "gatsby";
import React from "react";
import BlogPostPreview from "./blog-post-preview";
import colors from "../styles/colors";

import styled from "styled-components";

const PostList = styled.ul`
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
`;

const PostPreview = styled.li`
  margin-bottom: 36px;
  margin-top: 36px;
  a {
    text-decoration: none;
    box-shadow: inset 0 -5px 0 ${colors.blueMedium.hex()};
    color: ${colors.blue.hex()};
    &:hover {
      background-color: ${colors.blueLight.hex()};
      box-shadow: inset 0 -5px 0 ${colors.yellowDark.hex()};
      * {
        color: ${colors.pinkLight.hex()} !important;
      }
      cursor: pointer;
    }

    color: ${colors.blueLight.hex()};
  }
`;

const styles = {};

function BlogPostPreviewGrid(props) {
  return (
    <div>
      {props.title && <h2>{props.title}</h2>}
      <PostList>
        {props.nodes &&
          props.nodes.map(node => (
            <PostPreview key={node.id}>
              <BlogPostPreview {...node} isInList />
            </PostPreview>
          ))}
      </PostList>
    </div>
  );
}

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default BlogPostPreviewGrid;
